import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty
} from '@camberi/firecms'

import {
  inputType,
  ProductInterface,
  getProductProperties
} from './ProductInterface'

/*
const productCallbacks = buildEntityCallbacks({
  onPreSave: ({ collection, path, entityId, values, status }) => {
    values.title_nl = `${values.title_nl?.toLowerCase()}`
    values.title_en = `${values.title_en?.toLowerCase()}`
    return values
  }
})
 */

/// Thiago

const thiagoShoesInput: inputType = {
  name: 'Modellen',
  path: 'models',
  group: 'thiago'
}

const thiagoMaterialsInput: inputType = {
  name: 'Materialen en kleuren',
  path: 'materials',
  group: 'thiago'
}

const thiagoBeltsInput: inputType = {
  name: 'Bijpassende riemen',
  path: 'belts',
  group: 'thiago'
}

const thiagoBucklesInput: inputType = {
  name: 'Gespen',
  path: 'buckles',
  group: 'thiago'
}

const thiagoCustomsInput: inputType = {
  name: 'Customs',
  path: 'customs',
  group: 'thiago'
}

const thiagoShoesCollection = buildCollection<ProductInterface>(
  getProductProperties(thiagoShoesInput)
)
const thiagoMaterialsCollection = buildCollection<ProductInterface>(
  getProductProperties(thiagoMaterialsInput)
)
const thiagoBeltsCollection = buildCollection<ProductInterface>(
  getProductProperties(thiagoBeltsInput)
)
const thiagoBucklesCollection = buildCollection<ProductInterface>(
  getProductProperties(thiagoBucklesInput)
)
const thiagoCustomsCollection = buildCollection<ProductInterface>(
  getProductProperties(thiagoCustomsInput)
)

/// Guilhermo

const guilhermoShoesInput: inputType = {
  name: 'Modellen',
  path: 'models',
  group: 'guilhermo'
}
const guilhermoMaterialsInput: inputType = {
  name: 'Materialen en kleuren',
  path: 'materials',
  group: 'guilhermo'
}

const guilhermoBeltsInput: inputType = {
  name: 'Bijpassende riemen',
  path: 'belts',
  group: 'guilhermo'
}

const guilhermoBucklesInput: inputType = {
  name: 'Gespen',
  path: 'buckles',
  group: 'guilhermo'
}

const guilhermoCustomsInput: inputType = {
  name: 'Customs',
  path: 'customs',
  group: 'guilhermo'
}

const guilhermoSneakersInput: inputType = {
  name: 'Sneakers',
  path: 'sneakers',
  group: 'guilhermo'
}

const guilhermoShoesCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoShoesInput)
)
const guilhermoMaterialsCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoMaterialsInput)
)
const guilhermoBeltsCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoBeltsInput)
)
const guilhermoBucklesCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoBucklesInput)
)
const guilhermoCustomsCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoCustomsInput)
)

const guilhermoSneakersCollection = buildCollection<ProductInterface>(
  getProductProperties(guilhermoSneakersInput)
)

export {
  thiagoShoesCollection,
  thiagoMaterialsCollection,
  thiagoBeltsCollection,
  thiagoBucklesCollection,
  thiagoCustomsCollection,
  guilhermoShoesCollection,
  guilhermoMaterialsCollection,
  guilhermoBeltsCollection,
  // guilhermoBucklesCollection,
  guilhermoCustomsCollection,
  guilhermoSneakersCollection
}
